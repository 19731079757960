<template>
    <div class="coolDialog" v-if="dialogVisible" :style="{'width': dialogWidth, 'left': dialogLeft, 'right': dialogRight, 'transform': `${translate} ${scale}`, 'transform-origin': transformOrigin}">
        <img class="lefttop" src="@/assets/smartPark/lefttop.png" alt="">
        <img class="righttop" src="@/assets/smartPark/righttop.png" alt="">
        <img class="leftbottom" src="@/assets/smartPark/leftbottom.png" alt="">
        <img class="rightbottom" src="@/assets/smartPark/rightbottom.png" alt="">
        <div class="header">
            <div class="header-frame">
                <img :style="{'width': frameWidth}" src="@/assets/smartPark/frame.png" >
                <span class="header-title" :style="{'color': headerTitleColor}">{{dialogTitle}}</span>
            </div>
            <img class="crossSign" @click.stop="close" src="@/assets/smartPark/crossSign.png" alt="">
        </div>
        <slot name="dialog-content"></slot>
    </div>
</template>

<script>
export default {
    name: "CoolDialog",
    props: {
        dialogWidth: String,
        dialogLeft: {
            value: String,
            default: '50%',
        },
        scale: {
            value: String,
            default: '',
        },
        translate: {
            value:String,
            default: 'translate(-50%, -50%)'
        },
        transformOrigin: {
            value: String,
            default: '',
        },
        dialogRight: String,
        dialogTitle: String,
        frameWidth: String,
        dialogVisible: Boolean,
        headerTitleColor: String,
    },
    data() {
        return {

        }
    },
    methods: {
        close() {
            this.$emit('onClose');
        }
    }
}
</script>

<style lang="stylus" scoped>
.coolDialog
    position absolute
    top 50%
    background: rgba(51, 61, 176, 0.3);
    box-shadow: inset 0px 0px 40px 20px rgba(0, 196, 255, 0.4);
    .lefttop
        position absolute
        left 0
        top 0
    .righttop
        position absolute
        right 0
        top 0
    .leftbottom
        position absolute
        left 0
        bottom 0
    .rightbottom
        position absolute
        right 0
        bottom 0
    .header
        padding 20px 20px 0 20px
        display flex
        justify-content space-between
        align-items center
        .header-frame
            position relative
            img
                height 40px
            .header-title
                font-size 24px
                color #23CEFD
                position absolute
                left 10px
                line-height 40px
        .crossSign
            width 38px
            height 34px
            position relative
            z-index 10
</style>